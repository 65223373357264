//packages
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Engineering } from '@mui/icons-material';
//stores
import chat from '../stores/chat.store';
//style
import "../style/header.css"
//consts
import { URL } from '../tools/url';
import { vh } from '../tools/screen';

//this component renders the app header
const PageHeader = ({ chatRestart }) => {
    const [userOptions, setuserOptions] = useState(false);
    const [DevMode, setDevMode] = useState(false)

    const handleClick = (event) => {
        setuserOptions(event);
    };

    const navigate = useNavigate();

    useEffect(() => {
        let admin = localStorage.getItem('is_admin');
        if (admin === 'true') {
            setDevMode(true)
        }
        setuserOptions(false)

    }, [])


    const reloadFromSheet = async () => {
        await axios.get(`${URL}/api/resetGoogleSheet/?reset=""`);
        alert('Data reloaded!')
    }
    return (
        <div className="page-header">
            <Link className="page-header" to="/">
                <img onClick={() => { if (window.location.pathname === "/chatPage") chatRestart(); chat.endChat(); }} src="/images/logo.png" alt="Arrows Icon" className="header-logo-icon" />
            </Link>
            <div className="right-icons">
            {DevMode ?
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row-reverse" }}>
                    <img
                        onClick={() => { reloadFromSheet() }}
                        src="/images/reloadSpreadsheet.png"
                        alt="reload"
                        className="header-reload-icon" />
                    <span   
                        className="header-reload-icon"
                        title="Admin page"
                        onClick={() => navigate("/admin")}
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                    >
                        <Engineering
                            sx={{ color: "#0ae5a1"}}
                        />
                    </span>
                </div> : <></>}
                <img src="/images/bell.png" alt="Alerts" className="header-bell-icon" />
                <img onClick={() => { handleClick(!userOptions) }} src="/images/user.png" alt="User" className="header-user-icon" />

            </div>
            {userOptions ? <div className='userOptions'>
                <div className='userOption' style={{
                    borderWidth: vh(.2),
                    borderBottomColor: 'white',
                    borderTopColor: '#262D3B',
                    borderLeftColor: '#262D3B',
                    borderRightColor: '#262D3B',
                    borderStyle: 'solid'
                }} onClick={() => {
                    // navigate("/userSettings");
                }}>
                    <img src="/images/settings.png" alt="settings" />
                    <h2>Settings</h2>
                </div>
                <div className='userOption' onClick={() => {
                    chat.logout(); navigate("/login");
                }}>
                    <img src="/images/logout.png" alt="logout" />
                    <h2>Log out</h2>
                </div>

            </div>
                : <></>
            }
        </div >
    );
};

export default PageHeader;
