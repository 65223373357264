import { useEffect, useState } from "react";
import "../../../style/editCourse.css";
import { Parser } from "html-to-react";
import {
  Add,
  Article,
  Class,
  Description,
  ExpandMore,
  Info,
  QuestionAnswer,
  School,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useErrorPopup from "../../../tools/hooks/showError";
import axios from "axios";
import { URL } from "../../../tools/url";

const CourseList = () => {
  const [courses, setCourses] = useState(null);
  const [editingNew, setEditingNew] = useState(false);
  const [savingNew, setSavingNew] = useState(false);
  const [newCourseName, setNewCourseName] = useState("");

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const { data } = await axios.get(`${URL}/api/ceGetEditableContent`);
        console.log("content from server", data ?? []);
        setCourses(data?.courses ?? []);
      } catch (err) {
        console.error(err);
        showError("Couldn't get classification questions.");
      }
    };
    fetchContent();
  }, []);

  const saveNewCourse = (courseName) => {
    setSavingNew(true);
    setTimeout(() => {
      setSavingNew(false);
      setNewCourseName("");
    }, 2000);
  };

  return (
    <>
      <div className="staff-course-list">
        {courses ? (
          courses.length ? (
            courses.map((course) => (
              <Accordion
                key={course.id}
                className="staff-course-in-list"
                style={{ margin: "10px 0", background: "#99a4ff" }}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography className="staff-course-in-list-name">
                    <School />
                    COURSE: {course.name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="staff-course-in-list-details">
                    <div className="staff-course-in-list-description">
                      <Info /> {course.description}
                    </div>
                  </div>
                  <div className="staff-course-in-list-units">
                    {(course.units ?? []).map((unit) => (
                      <Accordion
                        key={unit.id}
                        className="staff-course-in-list-unit"
                        style={{ margin: "10px 0", background: "#ff9ac8" }}
                      >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography className="staff-course-in-list-unit-name">
                            <Class /> UNIT: {unit.name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div
                            className="staff-course-in-list-description"
                            style={{ background: "#ffcefb" }}
                          >
                            <Info /> {unit.description}
                          </div>
                          <div className="staff-course-in-list-lessons">
                            {[
                              ...(unit?.info_pages?.map((item) => {
                                return {
                                  ...item,
                                  type: "info-page",
                                };
                              }) ?? []),
                              ...(unit?.scenarios?.map((item) => {
                                return {
                                  ...item,
                                  type: "scenario",
                                };
                              }) ?? []),
                            ]
                              .sort((a, b) => (a.order > b.order ? 1 : -1))
                              .map((lesson) => (
                                <div
                                  key={`${lesson.type}${lesson.id}`}
                                  className="staff-course-in-list-lesson"
                                >
                                  <div>
                                    {lesson.type === "info-page" ? (
                                      <Accordion
                                        className="staff-course-in-list-lesson"
                                        style={{
                                          margin: "10px 0",
                                          background: "#f9f871",
                                        }}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMore />}
                                        >
                                          <Typography className="staff-course-in-list-lesson-name">
                                            <Article /> INFO PAGE: {lesson.name}
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <div
                                            className="staff-course-in-list-info-page"
                                            style={{ background: "white" }}
                                          >
                                            {Parser().parse(lesson.content)}
                                          </div>
                                          <div className="staff-course-in-list-edit-button-wrapper">
                                            <button
                                              className="prompt-generation-button"
                                              onClick={() =>
                                                navigate(
                                                  `/admin/courses/${course.id}/unit/${unit.id}/info-page/${lesson.id}`
                                                )
                                              }
                                            >
                                              EDIT INFO PAGE "
                                              {(
                                                lesson?.name ?? ""
                                              ).toUpperCase()}
                                              ""
                                            </button>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    ) : (
                                      <Accordion
                                        className="staff-course-in-list-lesson"
                                        style={{
                                          margin: "10px 0",
                                          background: "#ffd171",
                                        }}
                                      >
                                        <AccordionSummary
                                          expandIcon={<ExpandMore />}
                                        >
                                          <Typography className="staff-course-in-list-lesson-name">
                                            <QuestionAnswer /> SCENARIO:{" "}
                                            {lesson.name}
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          <div className="staff-course-in-list-scenario">
                                            <div
                                              className="staff-course-in-list-description"
                                              style={{
                                                backgroundColor: "#ffeeca",
                                              }}
                                            >
                                              <Description />{" "}
                                              {lesson.user_instructions}
                                            </div>
                                          </div>
                                          <div className="staff-course-in-list-edit-button-wrapper">
                                            <button
                                              className="prompt-generation-button"
                                              onClick={() =>
                                                navigate(
                                                  `/admin/courses/${course.id}/unit/${unit.id}/scenario/${lesson.id}`
                                                )
                                              }
                                            >
                                              EDIT SCENARIO "
                                              {(
                                                lesson?.name ?? ""
                                              ).toUpperCase()}
                                              "
                                            </button>
                                          </div>
                                        </AccordionDetails>
                                      </Accordion>
                                    )}
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div className="staff-course-in-list-edit-button-wrapper">
                            <button
                              className="prompt-generation-button"
                              onClick={() =>
                                navigate(
                                  `/admin/courses/${course.id}/unit/${unit.id}`
                                )
                              }
                            >
                              EDIT UNIT "{(unit?.name ?? "").toUpperCase()}"
                            </button>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                  <div className="staff-course-in-list-edit-button-wrapper">
                    <button
                      className="prompt-generation-button"
                      onClick={() => navigate(`/admin/courses/${course.id}`)}
                    >
                      EDIT COURSE "{(course?.name ?? "").toUpperCase()}""
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <div className="staff-course-in-list">No courses available</div>
          )
        ) : (
          <div className="staff-course-in-list">
            <div className="loader prompt-generation-loader">
              <div id="bar1" className="bar"></div>
              <div id="bar2" className="bar"></div>
              <div id="bar3" className="bar"></div>
            </div>
          </div>
        )}
        <div
          className="staff-course-in-list"
          style={{
            margin: "10px 0",
            background: "#99a4ff",
            display: "flex",
            alignItems: "center",
            height: "50px",
            padding: "0 15px",
          }}
        >
          {editingNew ? (
            <div
              className="staff-course-in-list-name"
              style={{ display: "flex", alignItems: "center" }}
            >
              <School />
              <input
                placeholder="Enter course name..."
                value={newCourseName}
                onChange={(e) => setNewCourseName(e.target.value)}
              ></input>
              {savingNew ? (
                <div
                  style={{
                    width: "200px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="loader prompt-generation-loader"
                    style={{ height: "35px" }}
                  >
                    <div id="bar1" className="bar"></div>
                    <div id="bar2" className="bar"></div>
                    <div id="bar3" className="bar"></div>
                  </div>
                </div>
              ) : (
                <>
                  <button
                    className="prompt-generation-button"
                    style={{
                      margin: "0 5px 0 0",
                      padding: "5px 10px",
                      background: false ? "#ccc" : "#0ae5a1",
                      border: "1px solid #fff",
                    }}
                    disabled={false}
                    onClick={() => saveNewCourse(newCourseName)}
                  >
                    SAVE
                  </button>
                  <button
                    className="prompt-generation-button"
                    style={{
                      margin: 0,
                      padding: "5px 10px",
                      background: "#fa7d5f",
                      border: "1px solid #fff",
                    }}
                    onClick={() => {
                      setEditingNew(false);
                      setNewCourseName("");
                    }}
                  >
                    CANCEL
                  </button>
                </>
              )}
            </div>
          ) : (
            <button
              className="prompt-generation-button"
              style={{
                margin: 0,
                padding: "5px 10px",
              }}
              onClick={() => setEditingNew(true)}
            >
              ADD A NEW COURSE
            </button>
          )}
        </div>
      </div>
      {ErrorPopupComponent}
    </>
  );
};

export default CourseList;
