import { Cancel, CheckCircle } from "@mui/icons-material";
import SkillTree from "./SkillTree";

const SkillInTree = ({
  item,
  toggleExpand,
  setSelectedSkill,
  selectedSkill,
  active,
  actions = [],
}) => {
  return (
    <div
      style={{
        alignItems: "center",
        width: "100%",
        position: "relative",
        backgroundColor: "#eee",
      }}
    >
      <div
        className={`skill-in-list ${
          selectedSkill?.id === item.id ? "skill-in-list-selected" : ""
        } ${actions.length ? "skill-in-list-actions-variant" : ""}`}
        onClick={() => {
          // ugly way of checking if this is the "choose a skill" or "add / edit / delete" skill variant
          if (active && !actions.length) {
            if (selectedSkill?.id === item.id) setSelectedSkill(null);
            else setSelectedSkill(item);
          }
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {item.child_skills.length ? (
              <button
                title={active ? (item.isCollapsed ? "Expand" : "Collapse") : ""}
                className="toggle-expand-button"
                onClick={(e) => {
                  if (active) {
                    e.stopPropagation();
                    toggleExpand(item.id);
                  }
                }}
              >
                {item.isCollapsed ? "+" : "-"}
              </button>
            ) : (
              <span style={{ width: "25px" }}></span>
            )}
            <div style={{ padding: "0 10px 0 5px" }}>
              <span style={{ display: "flex" }}>
                <strong>{item.name}</strong>&nbsp;
                <span
                  title={
                    active ? (item.is_active ? "Active" : "Not active") : ""
                  }
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {item.is_active ? (
                    <CheckCircle sx={{ fontSize: "15px", color: "#0ae5a1" }} />
                  ) : (
                    <Cancel sx={{ fontSize: "15px", color: "#fa7d5f" }} />
                  )}
                </span>
              </span>
              <div>{item.description}</div>
            </div>
          </div>
          <div className="skill-in-list-actions">
            {actions.map((ac) => (
              <button
                key={ac.title}
                title={
                  active
                    ? `${ac.title}${
                        ac.disabled ||
                        (ac.title === "Delete skill" &&
                          item?.child_skills?.length)
                          ? " (disabled)"
                          : ""
                      }`
                    : ""
                }
                onClick={() => ac.onClick(item)}
                className="toggle-expand-button"
                style={{
                  ...ac.style,
                  cursor: active
                    ? ac.title === "Delete skill" && item?.child_skills?.length
                      ? "not-allowed"
                      : "pointer"
                    : "default",
                }}
                disabled={
                  !active ||
                  ac.disabled ||
                  (ac.title === "Delete skill" && item?.child_skills?.length)
                }
              >
                {ac.icon}
              </button>
            ))}
          </div>
        </div>
      </div>
      {item.child_skills.length ? (
        <SkillTree
          isCollapsed={item.isCollapsed}
          data={item.child_skills}
          active={active}
          setSelectedSkill={(item) => setSelectedSkill(item)}
          selectedSkill={selectedSkill}
          actions={actions}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default SkillInTree;
