import { useEffect, useState } from "react";
import { Chip } from "@mui/material";

const AddPrompt = ({ builder, complete, disabled }) => {
  const [llmList, setLlmList] = useState([]);
  const [llm, setLlm] = useState(null);
  const [template, setTemplate] = useState("");

  useEffect(() => {
    setLlmList(builder?.out_supported_llms ?? []);
  }, [builder]);

  const chipSx = (selected) => {
    const basicObj = {
      m: 0.5,
      border: "1px solid #282c34",
    };
    return selected
      ? {
          ...basicObj,
          bgcolor: "#282c34",
          color: "#ffffff",
          "&:hover, &:active": {
            bgcolor: "#282c34",
          },
        }
      : {
          ...basicObj,
          "&:hover, &:active": {
            bgcolor: "inherit",
          },
        };
  };

  const addPrompt = async () => {
    complete({
      ...builder,
      question: {
        ...builder?.question,
        prompts: [
          ...builder?.question?.prompts,
          {
            llm_name: llm,
            prompt_str: template,
          },
        ],
      },
    });
  };

  return (
    <>
      <div
        style={{
          padding: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "10px",
          }}
        >
          <div style={{ width: "50%", paddingLeft: "15px" }}>
            Choose LLM
            <div
              style={{ display: "flex", flexWrap: "wrap", paddingTop: "4.5px" }}
            >
              {llmList.map((item) => (
                <Chip
                  sx={chipSx(llm === item)}
                  key={item}
                  label={item}
                  disabled={disabled}
                  onClick={() => setLlm(item)}
                />
              ))}
            </div>
          </div>
        </div>
        <div style={{ padding: "15px" }}>
          <div>Prompt text:</div>
          <textarea
            className="prompt-generation-textarea"
            value={template}
            onChange={(e) => setTemplate(e.target.value)}
            style={{
              height: "200px",
              marginTop: "5px",
            }}
          ></textarea>
        </div>
      </div>
      <button
        disabled={disabled || !llm || !template}
        className="prompt-generation-button generate-tests-button"
        onClick={addPrompt}
        title={!llm ? "Choose an LLM" : !template ? "Type in a prompt" : ""}
      >
        CREATE
      </button>
    </>
  );
};

export default AddPrompt;
