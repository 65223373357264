//packages
import React, { useState, useRef } from "react";
import axios from "axios";
//style
import '../../style/endChatPopup.css';
//tools
import { vh, vw } from "../../tools/screen"
import Button from "../button";
import { URL } from "../../tools/url";

import chat from "../../stores/chat.store";


const UserFeedbackPopup = ({ setUserFeedbackPopup, setRecord, turnId }) => {
    console.log('turnId: ', turnId);
    const [feedbackText, setFeedbackText] = useState("");
    const [validation, setValidation] = useState('');
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const textareaRef = useRef(null);

    const sendFeedback = async () => {
        try {
            const accessToken = localStorage.getItem('access_token');
            const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
            if (feedbackText && rating !== 0) {
                console.log('feedbackText: ', feedbackText);
                await axios.post(`${URL}/api/saveUserFeedback`, {
                    'conversationId': chat.chatSettings.conversationId,
                    'userId': decodedToken.user_id,
                    'feedback': feedbackText,
                    'turnId': turnId ? turnId : 0,
                    'rating': rating
                })
                setUserFeedbackPopup(false);
                setRecord(true);
            }
            else {
                setValidation('Please fill in your rating and feedback')
            }

        }
        catch (err) {
            setValidation('Error saving feedback, try again later')
        }
    }

    return (<>

        <div className="endPopupContainer" style={{ zIndex: 4 }}>

            <div className="back" onClick={() => {
                setUserFeedbackPopup(false);
                setRecord(true)
            }}
                style={{ alignSelf: 'flex-end', flexDirection: 'row', position: 'absolute', marginTop: vh(3) }}>
                <img src="/images/x_button.png" alt="back" style={{ height: vh(2), marginRight: vw(2) }} />
            </div>

            <h2 className="endTitle" style={{ marginTop: vh(3) }}>Give us feedback on the conversation:</h2>
            <div className="star-rating">
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                        <button
                            type="button"
                            key={index}
                            className={index <= (hover || rating) ? "on" : "off"}
                            onClick={() => setRating(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                        >
                            <span className="star">&#9733;</span>
                        </button>
                    );
                })}
            </div>
            <h2 className="endText" style={{ marginTop: vh(0), fontSize: vh(2.5) }}>
                Let us know how we can improve your Arrows experience:
            </h2>
            <div className="feedbackInput">
                <textarea
                    type="text"
                    ref={textareaRef}
                    aria-multiline={true}
                    rows={4}
                    placeholder="Let us know what you think..."
                    value={feedbackText}
                    onChange={(event) => {
                        setFeedbackText(event.target.value);
                    }}
                />
            </div>
            {validation ? <h2 className="validations">{validation}</h2> : <></>}

            <div className="centerButton">
                <Button
                    text='Send feedback'
                    style={{ backgroundColor: '#262D3B', marginTop: vh(2) }}
                    textStyle={{
                        color: 'white',
                        fontFamily: 'Assistant',
                        fontWeight: '600',
                        fontSize: vh(3),
                        marginRight: vw(1.25)
                    }}
                    alt='start'
                    onClick={() => {
                        sendFeedback();
                    }}
                />
            </div>

        </div >
    </>
    );
};

export default UserFeedbackPopup;
