//packages
import axios from 'axios';
import React, { useState, useEffect } from "react";
//consts
import { URL } from '../tools/url';



const Reload = () => {

    const [res, setRes] = useState('')

    useEffect(() => {
        runTests()
    }, [])
    const runTests = async () => {
        const { data } = await axios.get(`${URL}/api/runSkillTests`);
        setRes(data.res)
    }
    return (
        <div>
            <h2>data:</h2>
            <h3 style={{
                whiteSpace: "pre-wrap"
            }}>
                {res}
            </h3>
        </div>
    );
};

export default Reload;
