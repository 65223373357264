import { Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart } from 'recharts';


const ProgressGraph = ({ graphData }) => {
  return (
        <ResponsiveContainer width="68%" aspect="2">
          <LineChart width={500} height={300} data={graphData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="Positive Feedback" stroke="#82ca9d" />
            <Line type="monotone" dataKey="Cues" stroke="#eb4034" />
          </LineChart>
        </ResponsiveContainer>
  );
};

export default ProgressGraph;
