import AdminPage from "../components/admin/AdminPage";
import ClassificationQuestionsList from "../components/admin/classification-question/ClassificationQuestionsList";

const ClassificationQuestions = () => {
  return (
    <AdminPage currentLocation="Classification questions">
      <div className="prompt-generation-container">
        <div
          className={`prompt-generation-section`}
          style={{ borderBottom: "none" }}
        >
          <h1>Classification questions</h1>
          <br />
          <ClassificationQuestionsList
            chosenQuestion={null}
            setChosenQuestion={() => {}}
            active
            showDelete
            editOption
            addNew
          />
        </div>
      </div>
    </AdminPage>
  );
};

export default ClassificationQuestions;
