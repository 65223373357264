import axios from "axios";
import SkillTree from "../components/admin/skill/SkillTree";
import "../style/promptGeneration.css";
import { useEffect, useMemo, useRef, useState } from "react";
import { URL } from "../tools/url";
import { Add, Delete, Edit } from "@mui/icons-material";
import ConfirmDeletePopup from "../components/popups/admin/ConfirmDeletePopup";
import EnterSkillData from "../components/admin/skill/EnterSkillData";
import AdminPage from "../components/admin/AdminPage";
import useErrorPopup from "../tools/hooks/showError";

const EMPTY_SKILL = {
  id: null,
  name: "",
  description: "",
  is_active: true,
  failure_cue: "",
  failure_message: "",
  failure_explanation: "",
  owner: null,
};

const Skills = () => {
  const [skills, setSkills] = useState([]);
  const [flatSkills, setFlatSkills] = useState([]);
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);
  const [confirmDeletePending, setConfirmDeletePending] = useState(null);
  const [wipSkill, setWipSkill] = useState({});
  const [showEditForm, setShowEditForm] = useState(false);
  const [showReload, setShowReload] = useState(false);
  const editForm = useRef();
  const reloadSection = useRef();

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const fetchSkills = async () => {
    try {
      const { data } = await axios.get(`${URL}/api/get_all_skills/`);
      setSkills(data?.skills);
      console.log("data.skills", data?.skills);
    } catch (err) {
      console.error(err);
      showError("Couldn't get skills");
    }
  };

  // fetch data from server
  useEffect(() => {
    fetchSkills();
  }, []);

  const flattenSkillsArray = (arr) => {
    let result = [];

    arr.forEach((item) => {
      // Add the current item to the result array with its parent information
      result.push({ ...item });

      // If the item has children, recursively flatten them and add to the result array
      if (item.child_skills && item.child_skills.length > 0) {
        result = result.concat(flattenSkillsArray(item.child_skills));
      }
    });

    return result;
  };

  useEffect(() => setFlatSkills(flattenSkillsArray(skills)), [skills]);

  useEffect(() => {
    if (showEditForm)
      setTimeout(
        () => editForm.current.scrollIntoView({ behavior: "smooth" }),
        0
      );
  }, [showEditForm]);

  useEffect(() => {
    if (showReload)
      setTimeout(
        () => reloadSection.current.scrollIntoView({ behavior: "smooth" }),
        0
      );
  }, [showReload]);

  const addSkill = (parent) => {
    let skill = { ...EMPTY_SKILL };
    if (parent) {
      skill.parent_id = parent.id;
    }
    setWipSkill({ ...skill });
    setShowEditForm(true);
  };

  const editSkill = (skill) => {
    setWipSkill({ ...skill });
    setShowEditForm(true);
  };

  const deleteSkill = async () => {
    console.log("deleting skill", confirmDeletePending);
    try {
      const { data } = await axios.post(`${URL}/api/delete_skill/`, {
        ...confirmDeletePending,
      });
      if (data.res === "Success") {
        setShowReload(true);
      }
    } catch (err) {
      console.error(err);
      showError("Couldn't delete skill");
    }
  };

  const openConfirmDelete = (id) => {
    setConfirmDeletePending(id);
    setConfirmDeletePopup(true);
  };

  const actions = useMemo(
    () => [
      {
        title: "Add child skill",
        icon: <Add />,
        onClick: addSkill,
        disabled: false,
        style: { backgroundColor: "#10a0a0" },
      },
      {
        title: "Edit this skill",
        icon: <Edit />,
        onClick: editSkill,
        disabled: false,
        style: {},
      },
      {
        title: "Delete skill",
        icon: <Delete />,
        onClick: openConfirmDelete,
        disabled: false,
        style: { backgroundColor: "#fa7d5f" },
      },
    ],
    []
  );

  return (
    <AdminPage currentLocation="Skills">
      <div className="prompt-generation-container">
        <h1>Manage skills</h1>
        <div
          className={`prompt-generation-section ${
            showEditForm || showReload ? "prompt-generation-inactive" : ""
          }`}
          style={!(showEditForm || showReload) ? { border: "none" } : {}}
        >
          <SkillTree
            isCollapsed={false}
            active={!(showEditForm || showReload)}
            noArrow
            setSelectedSkill={() => {}}
            selectedSkill={null}
            data={skills}
            actions={actions}
          />
          <button
            title={
              !(showEditForm || showReload) ? "Add new skill (no parent)" : ""
            }
            onClick={() => addSkill(null)}
            className="add-new-skill-btn toggle-expand-button"
            style={{ backgroundColor: "#10a0a0" }}
            disabled={showEditForm || showReload}
          >
            <Add />
          </button>
        </div>
        {showEditForm ? (
          <div
            ref={editForm}
            className={`prompt-generation-section ${
              showReload ? "prompt-generation-inactive" : ""
            }`}
            style={showEditForm && !showReload ? { border: "none" } : {}}
          >
            <EnterSkillData
              active={showEditForm && !showReload}
              skill={wipSkill}
              skillsListFlat={flatSkills}
              complete={() => setShowReload(true)}
            />
          </div>
        ) : (
          <></>
        )}
        <ConfirmDeletePopup
          open={confirmDeletePopup}
          confirmDelete={deleteSkill}
          close={() => {
            setConfirmDeletePending(null);
            setConfirmDeletePopup(false);
          }}
        >
          Are you sure you want to delete this skill?
        </ConfirmDeletePopup>
        {showReload ? (
          <div className="prompt-generation-section" ref={reloadSection}>
            Skill successfully updated!
            <button
              className="prompt-generation-button"
              onClick={() => window.location.reload()}
            >
              Click to reload and see your changes
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      {ErrorPopupComponent}
    </AdminPage>
  );
};

export default Skills;
