import { useEffect, useState } from "react";
import SkillInTree from "./SkillInTree";

const SkillTree = ({
  data,
  noArrow = false,
  active,
  setSelectedSkill,
  selectedSkill,
  actions = [],
  isCollapsed = false,
}) => {
  const [displayedData, setDisplayedData] = useState(null);

  useEffect(() => {
    setDisplayedData((prev) =>
      data.map((item) => {
        return {
          ...item,
          isCollapsed:
            (prev && prev[prev.findIndex((i) => i?.id === item.id)]?.isCollapsed) ||
            false,
          isSelected: selectedSkill?.id === item.id,
        };
      })
    );
  }, [data, selectedSkill]);

  return (
    <div style={{ display: isCollapsed ? "none" : "block" }}>
      {displayedData ? (
        displayedData.length ? (
          displayedData.map((item, index) => (
            <span key={item.id} style={{ display: "flex" }}>
              {!index && !noArrow ? (
                <span
                  style={{
                    width: "35px",
                    display: "flex",
                    justifyContent: "flex-end",
                    fontSize: "15px",
                  }}
                >
                  ↳&nbsp;&nbsp;
                </span>
              ) : !noArrow ? (
                <span style={{ width: "22px" }}></span>
              ) : (
                <></>
              )}
              <SkillInTree
                key={index}
                item={item}
                toggleExpand={(id) => {
                  setDisplayedData((prev) =>
                    prev.map((i) => {
                      if (i.id === id) {
                        return { ...i, isCollapsed: !i.isCollapsed };
                      } else return i;
                    })
                  );
                }}
                setSelectedSkill={(item) => setSelectedSkill(item)}
                selectedSkill={selectedSkill}
                active={active}
                actions={actions}
              />
            </span>
          ))
        ) : (
          "No skills available"
        )
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="loader prompt-generation-loader">
            <div id="bar1" className="bar"></div>
            <div id="bar2" className="bar"></div>
            <div id="bar3" className="bar"></div>
          </div>
          <h3>Loading skill data...</h3>
        </div>
      )}
    </div>
  );
};

export default SkillTree;
