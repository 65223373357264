import React, { useState } from 'react';
import chat from '../stores/chat.store';
import "../style/userSettings.css";



const SettingsOpt = ({ settingsTabs }) => {


    return (
        <div className='settingsOptContainer'>
            <div>
                <div>
                    <h2>
                    </h2>
                </div>
                <div>
                    <h2>

                    </h2>
                </div>
            </div>
        </div >
    );
};

export default SettingsOpt;
