//packages
import React from "react";
//style
import '../../style/course.css'
//consts
import { vh, vw } from "../../tools/screen";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const CourseSkeleton = () => {



    return (
        <div style={{ overflowX: 'hidden', overflowY: 'hidden', display: 'flex', flexDirection: 'row' }}>

            <Skeleton style={{
                marginLeft: vw(2),
                width: vh(47), height: vh(45)
            }} />
            <Skeleton style={{
                marginLeft: vw(20),
                width: vh(47), height: vh(45)
            }} />


        </div >
    );
};

export default CourseSkeleton;
