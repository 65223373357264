import { Dialog, DialogActions, Typography } from "@mui/material";

const ConfirmDeletePopup = ({ open, confirmDelete, close, children }) => {
  return (
    <Dialog open={open}>
      <Typography
        sx={{ mt: 4, mb: 2, ml: 5, mr: 5, textAlign: "center" }}
        variant="h6"
      >
        {children}
      </Typography>
      <DialogActions sx={{ m: 1, justifyContent: "center" }}>
        <button className="prompt-generation-button" onClick={close}>
          CANCEL
        </button>
        <button
          className="prompt-generation-button"
          style={{ backgroundColor: "#fa7d5f" }}
          onClick={() => {
            confirmDelete();
            close();
          }}
        >
          DELETE
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeletePopup;