import { useEffect, useMemo, useState } from "react";
import ConvTest from "./ConvTest";
import { Dialog, IconButton, Typography } from "@mui/material";
import { Add, AutoFixHigh } from "@mui/icons-material";
import EnterTestSetDetails from "./EnterTestSetDetails";
import WarningPopup from "../../popups/admin/WarningPopup";

const MAX_TESTS_LENGTH = 20;

const TestSet = ({
  builder,
  setBuilder,
  save,
  loading,
  callingServer,
  setCallingServer,
  unsavedDefinitionChanges,
  unsavedTestChanges,
  unsavedPromptChanges,
  setUnsavedTestChanges,
}) => {
  const [tests, setTests] = useState([]);
  const [saving, setSaving] = useState(false);
  const [generateWarnPopupOpen, setGenerateWarnPopupOpen] = useState(false);
  const [generatePopupOpen, setGeneratePopupOpen] = useState(false);
  const [generatingTests, setGeneratingTests] = useState(false);
  const [saveWarnPopupOpen, setSaveWarnPopupOpen] = useState(false);

  const cantGenerate = useMemo(() => {
    return saving
      ? "busy saving"
      : tests.length >= MAX_TESTS_LENGTH
      ? `cannot have more than ${MAX_TESTS_LENGTH} tests`
      : callingServer
      ? "busy calling server"
      : unsavedDefinitionChanges
      ? "you have unsaved changes in name or definition"
      : unsavedPromptChanges
      ? "you have unsaved prompts changes"
      : unsavedTestChanges
      ? "you have unsaved test changes - save them before generating more"
      : !builder?.question?.definition
      ? "you have no saved definition"
      : "";
  }, [
    saving,
    tests,
    MAX_TESTS_LENGTH,
    callingServer,
    unsavedTestChanges,
    unsavedDefinitionChanges,
    unsavedPromptChanges,
    builder,
  ]);

  const cantAdd = useMemo(() => {
    return saving
      ? "busy saving"
      : tests.length >= MAX_TESTS_LENGTH
      ? `cannot have more than ${MAX_TESTS_LENGTH} tests`
      : callingServer
      ? "busy calling server"
      : unsavedDefinitionChanges
      ? "you have unsaved changes in name or definition"
      : unsavedPromptChanges
      ? "you have unsaved prompts changes"
      : !builder?.question?.definition
      ? "you have no saved definition"
      : "";
  }, [
    saving,
    tests,
    MAX_TESTS_LENGTH,
    callingServer,
    unsavedDefinitionChanges,
    unsavedPromptChanges,
    builder,
  ]);

  const cantSave = useMemo(() => {
    return (!tests?.length && !builder?.question?.tests?.length)
      ? "no tests to save"
      : saving
      ? "already saving"
      : callingServer
      ? "busy calling server"
      : !unsavedTestChanges
      ? "you haven't changed anything"
      : unsavedDefinitionChanges
      ? "you have unsaved changes in name or definition"
      : unsavedPromptChanges
      ? "you have unsaved prompts changes"
      : !builder?.question?.definition
      ? "you have no saved definition"
      : "";
  }, [
    saving,
    tests,
    callingServer,
    unsavedTestChanges,
    unsavedDefinitionChanges,
    unsavedPromptChanges,
    builder,
  ]);

  const cantEdit = useMemo(() => {
    return unsavedDefinitionChanges || unsavedTestChanges;
  }, [unsavedDefinitionChanges, unsavedPromptChanges]);

  useEffect(() => {
    setTests(
      builder?.question?.tests?.map((item) => {
        return {
          ...item,
          editing: false,
        };
      }) || []
    );
  }, [builder?.question?.tests]);

  useEffect(() => {
    if (
      JSON.stringify(
        [...tests].map((item) => {
          if (!item.editing) delete item.editing;
          return item;
        })
      ) !== JSON.stringify([...(builder?.question?.tests || [])])
    )
      setUnsavedTestChanges(true);
    else setUnsavedTestChanges(false);
  }, [tests, builder]);

  useEffect(() => {
    if (generatingTests) setCallingServer(true);
    else setCallingServer(false);
  }, [generatingTests]);

  const editTest = (test) => {
    setTests((prev) =>
      prev.map((item) => {
        if (item.id === test.id) return { ...test };
        return { ...item };
      })
    );
  };

  const addTest = () => {
    if (!cantAdd) {
      const id = Math.random();
      setTests((prev) => [
        ...prev,
        {
          test_conv: [
            ["B", ""],
            ["U", ""],
          ],
          editing: true,
          id: id,
          expected_result: "True",
        },
      ]);
    }
  };

  const deleteTest = (id) => {
    setTests((prev) => prev.filter((item) => item.id !== id));
  };

  const saveTests = () => {
    if (!cantSave) {
      setSaving(true);
      save({
        param_builder: {
          ...builder,
          question: {
            ...builder.question,
            tests: tests.map((item) => {
              delete item.editing;
              if (item.id > 0 && item.id < 1) delete item.id; // checks if new test (id created with Math.random)
              return item;
            }),
          },
        },
        definition_changed: false,
        tests_changed: true,
        prompts_changed: false,
      });
      setSaving(false);
    }
  };

  return (
    <>
      <div className="prompt-generation-section">
        <h2 style={{ marginBottom: "10px" }}>Tests</h2>
        {loading ? (
          <>
            <div className="loader prompt-generation-loader">
              <div id="bar1" className="bar"></div>
              <div id="bar2" className="bar"></div>
              <div id="bar3" className="bar"></div>
            </div>
          </>
        ) : (
          <>
            {tests.map((item, index) => (
              <ConvTest
                saving={saving}
                key={`${item.id}`}
                test={item}
                testNum={index + 1}
                setEditing={(bool) => {
                  setTests((prev) =>
                    prev.map((i) => {
                      return {
                        ...i,
                        editing: i.id === item.id ? bool : i.editing,
                      };
                    })
                  );
                }}
                editTest={(data) => editTest(data)}
                deleteTest={() => deleteTest(item.id)}
                disabled={cantEdit}
              />
            ))}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <button
                className="prompt-generation-button"
                onClick={addTest}
                disabled={!!cantAdd}
                title={cantAdd ? `Can't add tests: ${cantAdd}` : ""}
              >
                ADD NEW TEST (MANUALLY)
              </button>
              <button
                className="prompt-generation-button"
                disabled={!!cantGenerate}
                onClick={() => {
                  if (!cantGenerate) {
                    if (
                      builder?.question?.prompts?.length ||
                      unsavedPromptChanges
                    )
                      setGenerateWarnPopupOpen(true);
                    else setGeneratePopupOpen(true);
                  }
                }}
                style={{
                  margin: "0 5px",
                }}
                title={
                  cantGenerate ? `Can't generate tests: ${cantGenerate}` : ""
                }
              >
                GENERATE TESTS (SERVER)
              </button>
            </div>
            <button
              className="prompt-generation-button"
              onClick={() => {
                if (!cantSave) {
                  if (
                    builder?.question?.prompts?.length ||
                    unsavedPromptChanges
                  )
                    setSaveWarnPopupOpen(true);
                  else saveTests();
                }
              }}
              disabled={!!cantSave}
              title={cantSave ? `Can't save tests: ${cantSave}` : ""}
            >
              {saving ? "SAVING" : "SAVE TESTS"}
            </button>
            {saving ? (
              <div className="loader prompt-generation-loader">
                <div id="bar1" className="bar"></div>
                <div id="bar2" className="bar"></div>
                <div id="bar3" className="bar"></div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <Dialog
        open={generatePopupOpen}
        onClose={() => {
          if (!generatingTests) setGeneratePopupOpen(false);
        }}
      >
        <Typography
          sx={{
            mt: 4,
            mb: 2,
            ml: 5,
            mr: 5,
            textAlign: "center",
            width: "500px",
          }}
          variant="h6"
        >
          Generate tests
        </Typography>
        <EnterTestSetDetails
          tests={tests}
          MAX_TESTS_LENGTH={MAX_TESTS_LENGTH}
          complete={(data) => {
            setBuilder(data);
            setGeneratePopupOpen(false);
          }}
          builder={builder}
          generatingTests={generatingTests}
          setGeneratingTests={setGeneratingTests}
        />
      </Dialog>
      <WarningPopup
        open={generateWarnPopupOpen}
        close={() => setGenerateWarnPopupOpen(false)}
        confirm={() => setGeneratePopupOpen(true)}
        confirmText={"GENERATE ANYWAY"}
      >
        Are you sure you want to generate more tests?
        <br />
        WARNING: This will nullify all test results
      </WarningPopup>
      <WarningPopup
        open={saveWarnPopupOpen}
        close={() => setSaveWarnPopupOpen(false)}
        confirm={() => saveTests()}
        confirmText={"SAVE ANYWAY"}
      >
        Are you sure you want to save these tests?
        <br />
        WARNING: This will nullify all test results
      </WarningPopup>
    </>
  );
};
export default TestSet;
