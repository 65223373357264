import { useState, useCallback } from "react";
import { Alert, Snackbar } from "@mui/material";

// ErrorPopup Component
const ErrorPopup = ({ open, errorMessage, onClose, severity }) => {
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
        <Alert severity={severity} onClose={onClose}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

const useErrorPopup = () => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [severity, setSeverity] = useState("error");

  const showError = useCallback(
    (message = "An error occured.", severity = "error") => {
      setErrorMessage(message);
      setSeverity(severity);
      setOpen(true);
    },
    []
  );

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const ErrorPopupComponent = (
    <ErrorPopup
      open={open}
      errorMessage={errorMessage}
      onClose={handleClose}
      severity={severity}
    />
  );

  return [showError, ErrorPopupComponent];
};

export default useErrorPopup;
