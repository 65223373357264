//packages
import React, { useEffect, useState } from 'react';
import axios from 'axios';
//style
import "../style/chatBar.css";
//consts
import { URL } from '../tools/url';
//components
import chat from '../stores/chat.store';
import ConvInfo from './popups/convInfoHomePage';
import { vw } from '../tools/screen';

// this component returns the session bar, with start, pause, stop and reset option to the conversation
const ChatBarHeb = ({ chatRestart, open, setOpen }) => {

    const [popupInfo, setPopupInfo] = useState(false);
    const [DevMode, setDevMode] = useState(false)


    const reloadFromSheet = async () => {
        await axios.get(`${URL}/api/resetGoogleSheet/?reset=""`);
        alert('Data reloaded!')
    }


    useEffect(() => {
        let admin = localStorage.getItem('is_admin');
        if (admin === 'true') {
            setDevMode(true)
        }
    }, [])
    return (
        <>
            {popupInfo ?
                <div style={{ position: 'absolute', top: 0, left: 0 }}>

                    <ConvInfo convInfo={{ convId: chat.chatSettings.conversationId, convDesc: chat.chatSettings.convoDescription }} close={setPopupInfo} />
                </div>
                : <></>
            }
            <div className="option-bar-container">

                <div onClick={() => { setPopupInfo(true); }} className='reload-container'>
                    < img src="/images/info.png" alt="Reload" className="reload-icon" />
                    <p className='reload-text'>מידע</p>
                </div>
                <div onClick={() => { if (chatRestart) { chatRestart() }; }} className='reload-container'>
                    < img src="/images/reload.png" alt="Reload" className="reload-icon" />
                    <p className='reload-text'>התחל שיחה חדשה</p>
                </div>
                {DevMode ? <div onClick={() => { reloadFromSheet(); if (chatRestart) { chatRestart() }; }} className='reload-container'>
                    < img src="/images/reloadSpreadsheet.png" alt="ReloadSheet" className="reload-icon" />
                    <p className='reload-text'> טעינת מידע</p>
                </div> : <div className='reload-container' style={{ paddingLeft: vw(12) }}></div>}
            </div>
        </> 
    );
};

export default ChatBarHeb;
