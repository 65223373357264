import React from 'react';
import "../style/userSettings.css";



const SettingsTab = ({ settingsTabs }) => {

    return (
        <div className='settingsTabContainer'>
            <div className='settingOpts'>
                {settingsTabs.map((opt) => {
                    return (
                        <div className='settingOpt'>
                            <img
                                src={opt.img}
                                alt="user"
                            />
                            <h2>
                                {opt.name}
                            </h2>
                        </div>

                    )
                })}

            </div>
        </div >
    );
};

export default SettingsTab;
