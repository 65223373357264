import axios from "axios";
import { URL } from "../../../tools/url";
import { useState, useEffect } from "react";
import useErrorPopup from "../../../tools/hooks/showError";
import "../../../style/filterBar.css";
import ProgressGraph from "./ProgressGraph.jsx"

const ProgressDisplay = ({ currentLocation, children }) => {
  const [skillOptions, setSkillOptions] = useState();
  const [userOptions, setUserOptions] = useState();
  const [graphData, setGraphData] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [showError, ErrorPopupComponent] = useErrorPopup();
  const [user, setUser] = useState(null);
  const [skill, setSkill] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(true)

  // get all the skills from the DB and insert them into the dropdown skill select
  const fetchSkills = async () => {
    try {
      const { data } = await axios.get(`${URL}/api/get_all_skills/`);
      setSkillOptions(data?.skills.map(skill => {
        return (<option key={skill.name} value={skill.name}>
          {skill.name}
          </option>)
        }));
    } catch (err) {
      console.error(err);
      showError("Couldn't get skills");
    }
  };

  // get all the users from the DB and insert them into the dropdown user select
  const fetchUsers = async () => {
    try {
      const { data } = await axios.get(`${URL}/api/getUsersList/`);
      setUserOptions(data?.users.map(user => {
        return (<option key={user.username} value={user.username}>
          {user.username}
        </option>)}
        ));
    } catch (err) {
      console.error(err);
      showError("Couldn't get users");
    }
  };

  // divide the array into sub array based on the object's key
  const groupBy = (arr, key_getter) => {
    const map = new Map();
    arr.forEach((se) => {
         const key = key_getter(se);
         const collection = map.get(key);
         if (!collection) {
             map.set(key, [se]);
         } else {
             collection.push(se);
         }
    });
    return map;
  }

  // send the given information to get the relevant skill events to show
  const handleSubmit = async (event) => {
    event.preventDefault();
    // initializations
    const graphDataArr = [];
    setGraphData(graphDataArr);
    setSubmit(false);
    const form = event.target;
    try {
      // get the relevant skill events given the user and skill
      const data = await axios.get(`${URL}/api/getUserSkillEvents/?id=${form[0].value}&name=${form[1].value}`);
      const skill_events = data.data.skillEvents;
      let users_num = 1;
      if (form[0].value === "All users") {
        users_num = form[0].length - 2;
      }
      // group by date and count
      const skill_events_by_date = groupBy(skill_events, se => se.date);
      const dates = [...new Set(skill_events.map(se => se.date))];
      for (const date of dates) {
        const skill_events_in_date = skill_events_by_date.get(date);
        const pos = skill_events_in_date.filter((se) => se.type === "POS_FEEDBACK");
        const cue = skill_events_in_date.filter((se) => se.type === "CUE");
        let pos_num;
        let cue_num;
        if (form[1].value === "All skills") {
          const pos_skill_events_by_skill = groupBy(pos, se => se.skill);
          let pos_sum = 0;
          for (const [skill, arr] of pos_skill_events_by_skill.entries()) {
            pos_sum += arr.length;
          }
          const cue_skill_events_by_skill = groupBy(cue, se => se.skill);
          let cue_sum = 0;
          for (const [skill, arr] of cue_skill_events_by_skill.entries()) {
            cue_sum += arr.length;
          }
          const pos_skill_num = pos_skill_events_by_skill.size;
          pos_num = pos_skill_num > 0 ? pos_sum / pos_skill_num : 0;
          const cue_skill_num = cue_skill_events_by_skill.size;
          cue_num = cue_skill_num > 0 ? cue_sum / cue_skill_num : 0;
          if (form[0].value === "All users") {
            pos_num /= users_num;
            cue_num /= users_num;
          }
        } else if (form[0].value === "All users") {
          pos_num = pos.length / users_num;
          cue_num = cue.length / users_num;
        } else {
          pos_num = pos.length;
          cue_num = cue.length;
        }
        if (pos_num || cue_num) {
          graphDataArr.push({"name": date,
            "Positive Feedback": pos_num,
            "Cues": cue_num});
        }
      }
      setGraphData(graphDataArr);
      setSubmit(true);
      console.log("data.skillEvents", data.data.skillEvents);
    } catch (err) {
      console.error(err);
      showError("Couldn't show graph");
    }
  };

  // set the users list and skill list
  useEffect(() => {
    fetchSkills();
    fetchUsers();
  }, []);

  // prevent illegal requests
  useEffect(() => {
    if (user && skill) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    setSubmit(false);
  }, [user, skill]);


  return (
    <>
    <div>
      <form onSubmit={handleSubmit} class="flexbox-container">
        <label class="filter-bar">Select user:</label>
        <select class="rectangle-object" name="selected_user" onChange={e => setUser(e.target.value)}>
          <option value="" selected={true} disabled={true}>--- Please choose a user ---</option>
          <option value="All users">- All users -</option>
          { userOptions }
        </select>
        <label class="filter-bar">Select skill:</label>
        <select class="rectangle-object" name="selected_skill" onChange={e => setSkill(e.target.value)}>
          <option value="" selected={true} disabled={true}>--- Please choose a skill ---</option>
          <option value="All skills">- All skills -</option>
          { skillOptions }
        </select>
        <button class="show-graph" type="submit" disabled={buttonDisabled}>Show skill progress graph</button>
      </form>
    </div>
    <div width="800" height="500">
      { submit ? (<ProgressGraph graphData={graphData} />) : ( <></> ) }
    </div>
    </>
  );
};

export default ProgressDisplay;
