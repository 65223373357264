import AdminPage from "../components/admin/AdminPage";
import ProgressDisplay from "../components/admin/skills-progress-graph/ProgressDisplay";

const Progress = () => {
  return (
    <AdminPage currentLocation="Progress">
      <div className="prompt-generation-container">
        <h1>Progress</h1>
        <ProgressDisplay />
      </div>
    </AdminPage>
  );
};

export default Progress;
