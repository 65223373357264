import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { URL } from "../../../tools/url";
import { decodeJSONFromServer } from "../../../tools/admin_enums";
import { Skeleton } from "@mui/material";
import WarningPopup from "../../popups/admin/WarningPopup";
import useErrorPopup from "../../../tools/hooks/showError";

const ClassificationQuestionDefinition = ({
  builder,
  setBuilder,
  save,
  loading,
  existingNames,
  callingServer,
  setCallingServer,
  unsavedDefinitionChanges,
  unsavedTestChanges,
  unsavedPromptChanges,
  setUnsavedDefinitionChanges,
}) => {
  const [questionName, setQuestionName] = useState("");
  const [definition, setDefinition] = useState("");
  const [questions, setQuestions] = useState([]);
  const [refinedLoader, setRefinedLoader] = useState(false);
  const [prevDefinitions, setPrevDefinitions] = useState([]);

  const [questionNameError, setQuestionNameError] = useState("");
  const [definitionError, setDefinitionError] = useState("");

  const [confirmRefine, setConfirmRefine] = useState(false);
  const [confirmSave, setConfirmSave] = useState(false);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  useEffect(() => {
    setQuestionName(builder?.question?.name ?? "");
    setDefinition(builder?.question?.definition ?? "");
    setPrevDefinitions(
      builder?.question?.definition_history
        ? builder?.question?.definition_history.split("*******")
        : []
    );
    setQuestions(builder?.question?.clarification_questions || []);
  }, [builder?.question]);

  useEffect(() => {
    if (
      builder?.question?.name !== questionName ||
      builder?.question?.definition !== definition
    )
      setUnsavedDefinitionChanges(true);
    else setUnsavedDefinitionChanges(false);
  }, [builder, questionName, definition]);

  const cantRefine = useMemo(() => {
    return refinedLoader
      ? "busy loading"
      : callingServer
      ? "server is being called"
      : !definition
      ? "there is no definition to refine"
      : unsavedTestChanges
      ? "you have unsaved changes in your test set"
      : unsavedPromptChanges
      ? "you have unsaved prompts changes"
      : "";
  }, [
    definition,
    callingServer,
    refinedLoader,
    unsavedTestChanges,
    unsavedPromptChanges,
  ]);

  const cantSave = useMemo(() => {
    if (!questionName && !definition)
      return "enter question name and definition";
    return !questionName
      ? "enter question name"
      : !definition
      ? "enter question definition"
      : callingServer
      ? "server is being called"
      : !unsavedDefinitionChanges
      ? "you haven't changed anything"
      : "";
  }, [questionName, callingServer, unsavedDefinitionChanges]);

  const cantEdit = useMemo(() => {
    return unsavedTestChanges
      ? "you have unsaved changes in your test set"
      : unsavedPromptChanges
      ? "you have unsaved prompts changes"
      : "";
  }, [unsavedTestChanges, unsavedPromptChanges]);

  // refined definition and clarification questions from server
  const sendEnteredDefinition = async () => {
    setRefinedLoader(true);
    setCallingServer(true);
    if (!cantRefine) {
      try {
        const { data } = await axios.post(
          `${URL}/api/classification_builder_refine_definition/`,
          {
            param_builder: {
              ...builder,
              question: {
                ...builder.question,
                name: questionName,
                definition_history: (definition !== builder?.question?.definition
                  ? [...prevDefinitions, builder.question?.definition]
                  : prevDefinitions
                ).join("*******"),
                definition: definition,
              },
            },
          }
        );
        setBuilder(decodeJSONFromServer(data));
      } catch (err) {
        console.error(err);
        showError("Couldn't refine definition");
      }
      setRefinedLoader(false);
      setCallingServer(false);
    }
  };

  // save the question info (delete all tests and prompts if changed)
  const saveQuestionInfo = () => {
    if (questionName && !questionNameError && !definitionError) {
      save({
        param_builder: {
          ...builder,
          question: {
            ...builder.question,
            name: questionName,
            definition_history: (definition !== builder?.question?.definition
              ? [...prevDefinitions, builder.question?.definition]
              : prevDefinitions
            ).join("*******"),
            definition: definition,
          },
        },
        definition_changed: true,
        tests_changed: false,
        prompts_changed: false,
      });
    }
  };

  const onChangeQuestionName = (text) => {
    if (!cantEdit) {
      setQuestionName(text);
      if (!text) setQuestionNameError("Question name must not be empty");
      else if (existingNames?.includes(text))
        setQuestionNameError("Question with this name already exists");
      else setQuestionNameError("");
    }
  };

  const onChangeDefinition = (text) => {
    if (!cantEdit) {
      setDefinition(text);
      if (!text) setDefinitionError("Definition must not be empty");
      else setDefinitionError("");
    }
  };

  return (
    <>
      <div className={`prompt-generation-section`}>
        <h2 style={{ marginBottom: "10px" }}>Question info</h2>
        {loading ? (
          <>
            loading name...
            <Skeleton
              variant="rectangle"
              height={40}
              sx={{ mt: 1, borderRadius: "5px" }}
            />
            <br />
            loading definition...
            <Skeleton
              variant="rectangle"
              height={100}
              sx={{ mt: 1, borderRadius: "5px" }}
            />
            <div></div>
          </>
        ) : (
          <>
            <div
              className={`prompt-generation-input ${
                questionNameError ? "prompt-generation-input-error" : ""
              }`}
              style={{ margin: "10px 0 10px 0" }}
            >
              <label htmlFor="question-name">
                Question name <span style={{ color: "#fa7d5f" }}>*</span>
              </label>
              <textarea
                className="prompt-generation-textarea"
                style={{ height: "fit-content" }}
                name="question-name"
                placeholder="Enter question name..."
                value={questionName}
                disabled={!!cantEdit}
                title={cantEdit ? `Can't edit: ${cantEdit}` : ""}
                onChange={(e) => onChangeQuestionName(e.target.value)}
              />
              <div className="prompt-generation-input-error-message">
                {questionNameError}
              </div>
            </div>
            {prevDefinitions?.length ? (
              <>
                Definition history:
                <ol className="prompt-generation-prev-definitions">
                  {prevDefinitions.map((pd) => (
                    <li key={`${pd}${Math.random()}`}>{pd}</li>
                  ))}
                </ol>
              </>
            ) : (
              <></>
            )}
            <div
              className={`prompt-generation-input ${
                definitionError ? "prompt-generation-input-error" : ""
              }`}
            >
              <label htmlFor="refined-definition">
                {refinedLoader
                  ? "loading definition..."
                  : `Definition ${
                      questions?.length
                        ? "(see questions below and edit if necessary)"
                        : ""
                    }`}
              </label>
              <textarea
                disabled={refinedLoader || cantEdit}
                className="prompt-generation-textarea"
                name="refined-definition"
                style={{
                  background: refinedLoader ? "#cfcfcf" : "inherit",
                }}
                placeholder={
                  refinedLoader
                    ? "loading definition..."
                    : `Enter classification question definition`
                }
                value={definition}
                onChange={(e) => onChangeDefinition(e.target.value)}
                title={cantEdit ? `Can't edit: ${cantEdit}` : ""}
              />
              <div className="prompt-generation-input-error-message">
                {definitionError}
              </div>
            </div>
            <div className="prompt-generation-questions">
              {refinedLoader ? (
                <div className="loader prompt-generation-loader">
                  <div id="bar1" className="bar"></div>
                  <div id="bar2" className="bar"></div>
                  <div id="bar3" className="bar"></div>
                </div>
              ) : questions?.length ? (
                <div>
                  <h4>Clarification questions from the server:</h4>
                  {questions.map((item, index) => (
                    <div key={`question-${index}`}>
                      {index + 1}. {item}
                    </div>
                  ))}
                </div>
              ) : (
                "No clarification questions"
              )}
            </div>
            <div style={{ marginTop: "10px" }}>
              <button
                disabled={!!cantRefine}
                title={cantRefine ? `Can't refine: ${cantRefine}` : ""}
                className="prompt-generation-button"
                onClick={() => {
                  if (
                    !builder?.question?.tests?.length &&
                    !builder?.question?.prompts?.length &&
                    !unsavedTestChanges &&
                    !unsavedPromptChanges
                  )
                    sendEnteredDefinition();
                  else setConfirmRefine(true);
                }}
              >
                REFINE
              </button>
              <button
                disabled={!!cantSave}
                title={cantSave ? `Can't save: ${cantSave}` : ""}
                className="prompt-generation-button"
                onClick={() => {
                  if (
                    !builder?.question?.tests?.length &&
                    !builder?.question?.prompts?.length &&
                    !unsavedTestChanges &&
                    !unsavedPromptChanges
                  )
                    saveQuestionInfo();
                  else setConfirmSave(true);
                }}
              >
                SAVE
              </button>
            </div>
          </>
        )}
      </div>
      <WarningPopup
        open={confirmRefine}
        close={() => setConfirmRefine(false)}
        confirm={() => sendEnteredDefinition()}
        confirmText="REFINE"
      >
        Are you sure you want to refine this definition?
        <br />
        WARNING: This will delete all{" "}
        {builder?.question?.tests?.length
          ? builder?.question?.prompts?.length
            ? "tests and prompts"
            : "tests"
          : "prompts"}
      </WarningPopup>
      <WarningPopup
        open={confirmSave}
        close={() => setConfirmSave(false)}
        confirm={() => saveQuestionInfo()}
        confirmText="SAVE"
      >
        Are you sure you want to save this info?
        <br />
        WARNING: This will delete all{" "}
        {builder?.question?.tests?.length
          ? builder?.question?.prompts?.length
            ? "tests and prompts"
            : "tests"
          : "prompts"}
      </WarningPopup>
      {ErrorPopupComponent}
    </>
  );
};

export default ClassificationQuestionDefinition;
