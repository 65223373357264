//packages
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//components
import PageHeader from "../components/header";
//stores
import chat from "../stores/chat.store";
//style
import '../style/course.css'
//consts
import { URL } from "../tools/url";
import { vh, vw } from "../tools/screen";
import Button from "../components/button";
import ConvInfo from '../components/popups/convInfoHomePage'

import 'react-loading-skeleton/dist/skeleton.css'
import CourseSkeleton from "../components/skeletons/courseSk";
import NoTokens from "../components/popups/noTokens";

const Course = () => {

    const navigate = useNavigate();

    const [DevMode, setDevMode] = useState(false)
    const [load, setLoad] = useState(true)
    const [topics, setTopics] = useState([]);
    const [userPlacment, setuserPlacment] = useState({ topicIndex: 0, convIndex: 0 });
    const [openInfoPopup, setOpenInfoPopup] = useState({})
    const [noTokensPopup, setNoTokensPopup] = useState(false)


    //fetches data from the server
    useEffect(() => {
        let admin = localStorage.getItem('is_admin');
        if (admin === 'true') {
            setDevMode(true)
        }
        const fetchData = async () => {
            try {
                setLoad(true)
                let courseId = localStorage.getItem('courseId')
                const { data } = await axios.get(`${URL}/api/getCourseContent/?sessionId=${chat.chatSettings.sessionId}&courseId=${courseId}`)
                chat.setSkills(data.skills)
                setLoad(false)
                let topicStatus = data.topics.map((topic) => {
                    let convStatus = topic?.items.map((conv) => {

                        return ({ ...conv, status: 'done', type: conv.type })
                    })
                    // if (topic.items.length > 0) {
                    //     return ({ ...topic, status: 'done', items: convStatus })
                    // }
                    // else{
                    return ({ ...topic, status: 'done', items: convStatus })

                    // }
                })
                setTopics(topicStatus);
                let topicIndex = lastIndexOfDone(data.topics, 'done')
                let convIndex = lastIndexOfDone(data.topics[topicIndex], 'done')
                if (!convIndex) {
                    selectUserPlacment(topicIndex - 1, data.topics[topicIndex].items.length - 1)
                }
                selectUserPlacment(topicIndex, convIndex)
            } catch (error) {
                console.error("Error retrieving data:", error);
            }
        };
        fetchData();

    }, []);



    let startConversationForDetails = async (name, details, id, topicName, skills) => {
        chat.setConversation(name); //Set values in chat store 
        chat.setConvoDescription(details);
        chat.setConversationId(id);
        chat.setTopic(topicName);
        chat.setConvSkills(skills)
        let tokens = await checkTokens()
        tokens > 0 ? navigate("/chatPage") : setNoTokensPopup(true);

    };
    let startConversationForDetailsNoAvatar = async (name, details, id, topicName, skills) => {
        chat.setConversation(name); //Set values in chat store 
        chat.setConvoDescription(details);
        chat.setConversationId(id);
        chat.setTopic(topicName);
        chat.setConvSkills(skills)
        navigate("/chatPageDev");
    };

    const checkTokens = async () => {
        try {
            const accessToken = localStorage.getItem('access_token');
            const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
            const { data } = await axios.get(`${URL}/api/checkTokens/?userId=${decodedToken.user_id}`);
            return data.tokens
        }
        catch (e) {
            return 1
        }
    }

    const lastIndexOfDone = (array, key) => {
        if (array) {
            for (let i = array?.length - 1; i >= 0; i--) {
                if (array[i]?.status === key) {
                    return i;
                }
            }

        }
        else return 0

    };

    const selectUserPlacment = (topicIndex, convIndex) => {
        setuserPlacment({ topicIndex: topicIndex, convIndex: convIndex })
    }

    const calculateTopicLineHeight = (userPlacment, topic) => {
        if (userPlacment) {
            return 30 + 5.25 * topic.items.length
        }
        else {
            return 20 + 5.25 * topic.items.length
        }
    }
    const calculateConvLineHeight = (currentConv) => {
        if (currentConv) {
            return 12.5
        }
        else {
            return 5
        }
    }

    return (
        <div style={{ width: vw(100), height: vh(100), overflow: 'hidden' }}>

            <PageHeader />
            <div className="back" onClick={() => {
                localStorage.removeItem('courseId')
                navigate("/")
            }}>
                <img src="/images/back.png" alt="back" style={{ height: vh(2) }} />
                <h2>Back to courses</h2>
            </div>
            {openInfoPopup?.convId ? <ConvInfo convInfo={openInfoPopup} close={setOpenInfoPopup} /> : <></>}
            {noTokensPopup ? <NoTokens close={() => { setNoTokensPopup(false) }} /> : <></>}

            {load ?
                <div className="progressBar" style={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
                    <CourseSkeleton />
                </div> :
                <div className="progressBar">
                    <div className="topics">
                        {topics.length > 0 ? topics.map((topic, index) => {
                            let lineHeight = calculateTopicLineHeight(userPlacment.topicIndex === index, topic)

                            return (
                                <div className="topicContainer" key={index}>
                                    {topics.length > index + 1 ? <div style={topic.status === 'done' ? { height: vh(lineHeight), backgroundColor: '#262D3B' } : { height: vh(14), }} className="progressLine">
                                    </div> : <></>}
                                    {topic.status === 'done' ?
                                        <img src="/images/topicNumN.png" alt="number decore" className="topicNum" />
                                        :
                                        <div style={topic.status === 'done' ? { opacity: 0 } : { backgroundColor: '#262D3B', zIndex: 3 }} className="topicNum" >
                                        </div>}
                                    <div className="topicTexts">
                                        <h2 className="topicTitle">{topic.topicName}</h2>

                                        {topic.status === 'done' ? <>
                                            {topic.items.length !== 0 ?
                                                <>
                                                    <div className="lessonsTitle"></div>
                                                    {topic.items.map((conv, index2) => {
                                                        let isSelected = userPlacment.topicIndex === index && userPlacment.convIndex === index2
                                                        let lessonLineHeight = calculateConvLineHeight(isSelected)
                                                        return (
                                                            <div className="lesson" onClick={() => { if (conv.status === 'done') { selectUserPlacment(index, index2) } }}>
                                                                {topic.items.length > index2 + 1 ?
                                                                    <div
                                                                        style={conv.status === 'done' ?
                                                                            { backgroundColor: '#10A0A0', height: vh(lessonLineHeight) } :
                                                                            index2 === topic.items.length - 1 ?
                                                                                { display: 'none', height: vh(lessonLineHeight) } : { height: vh(lessonLineHeight) }
                                                                        }
                                                                        className="lessonProgressLine">
                                                                    </div> : <></>}
                                                                {conv.status === 'done' ?
                                                                    < img src="/images/lessonNumN.png" alt="number decore" className="lessonNum" /> :

                                                                    <div style={conv.status === 'done' ? { backgroundColor: '#10A0A0' } : {}} className="lessonNum" >
                                                                    </div>}

                                                                <div style={{ display: 'flex', flexDirection: 'row' }}>

                                                                    <h2 className="lessonName">
                                                                        {conv.type === "Conversation" ? "Scenario" : "Lesson"}: {conv.type === "Conversation" ? conv.content.convName : conv.content.title}
                                                                    </h2>
                                                                    {
                                                                        conv.type === "Conversation" ?
                                                                            < img src="/images/information.png" alt="info" className="lessonInfo" onClick={() => { setOpenInfoPopup(conv.content); }} /> : <></>
                                                                    }
                                                                </div>

                                                                {userPlacment.topicIndex === index && userPlacment.convIndex === index2 ?
                                                                    <div style={{ display: 'flex', flexDirection: 'row', height: 'fit-content' }}>
                                                                        {conv.type === "Conversation" ? <Button
                                                                            text='Start'
                                                                            icon='./images/playWhite.png'
                                                                            style={{ marginTop: vh(2), marginLeft: vw(3.5), backgroundColor: '#262D3B', fontWeight: '400' }}
                                                                            alt='start'
                                                                            onClick={() => {
                                                                                startConversationForDetails(conv.content.convName, conv.content.convDesc, conv.content.convId, topic.topicName, conv.content.skillsList)
                                                                            }}
                                                                        /> :
                                                                            <Button
                                                                                text='Read'
                                                                                icon='./images/read.png'
                                                                                style={{ marginTop: vh(2), marginLeft: vw(3.5), backgroundColor: '#10a0a0a0', fontWeight: '400' }}
                                                                                alt='read'
                                                                                iconstyle={{ marginTop: vh(.75), paddingRight: vw(1), paddingLeft: vw(.5) }}
                                                                                onClick={() => {
                                                                                    localStorage.setItem('page_content', conv.content.mdContent);
                                                                                    localStorage.setItem('page_title', conv.content.title);

                                                                                    navigate("/aboutChapter");

                                                                                }}
                                                                            />}
                                                                        {DevMode && conv.type === "Conversation" ?
                                                                            <Button
                                                                                text='Start without avatar'
                                                                                icon='./images/playWhite.png'
                                                                                style={{ marginTop: vh(2), marginLeft: vw(1.5), backgroundColor: '#262D3B', fontWeight: '400' }}
                                                                                alt='start'
                                                                                onClick={() => {
                                                                                    startConversationForDetailsNoAvatar(conv.content.convName, conv.content.convDesc, conv.content.convId, topic.topicName, conv.content.skillsList)
                                                                                }}
                                                                            /> : <></>}
                                                                    </div>
                                                                    : <></>}
                                                            </div>
                                                        )
                                                    })}</> : <h2>coming soon...</h2>}


                                        </> : <></>}
                                    </div>
                                </div>
                            )
                        }) : <h2>No contnet yet :\</h2>}
                    </div>
                </div>}
        </div >
    );
};

export default Course;
