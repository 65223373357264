import axios from "axios";
import { URL } from "../../../tools/url";
import { decodeJSONFromServer } from "../../../tools/admin_enums";
import { useState } from "react";
import useErrorPopup from "../../../tools/hooks/showError";

const EnterTestSetDetails = ({
  tests,
  MAX_TESTS_LENGTH,
  builder,
  complete,
  generatingTests,
  setGeneratingTests
}) => {
  const [numPosTestsToGenerate, setNumPosTestsToGenerate] = useState(5);
  const [numNegTestsToGenerate, setNumNegTestsToGenerate] = useState(5);

  const [showError, ErrorPopupComponent] = useErrorPopup();

  const generateTests = async () => {
    if (builder?.question?.definition) {
      setGeneratingTests(true);
      try {
        const { data } = await axios.post(
          `${URL}/api/classification_builder_generate_labeled_examples/`,
          {
            param_builder: {
              ...builder,
              question: {
                ...builder.question,
                tests: tests.map((item) => {
                  delete item.editing;
                  if (item.id > 0 && item.id < 1) delete item.id; // checks if new test (id created with Math.random)
                  return item;
                }),
              },
              in_num_of_pos_tests_to_generate: numPosTestsToGenerate,
              in_num_of_neg_tests_to_generate: numNegTestsToGenerate,
            },
          }
        );
        const decodedData = decodeJSONFromServer(data);
        complete({ ...decodedData });
      } catch (err) {
        console.error(err);
        showError("Couldn't generate tests");
      }
      setGeneratingTests(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px",
          justifyContent: "space-evenly",
        }}
      >
        <div style={{ width: "35%" }}>
          Number of positive tests ({numPosTestsToGenerate}):
          <input
            disabled={generatingTests}
            type="number"
            value={numPosTestsToGenerate}
            onChange={(e) => {
              if (!e.target.value) setNumPosTestsToGenerate(0);
              else if (
                e.target.value <=
                  MAX_TESTS_LENGTH - tests.length - numNegTestsToGenerate &&
                e.target.value >= 0
              )
                setNumPosTestsToGenerate(Number(e.target.value) || 0);
            }}
            min={0}
            max={MAX_TESTS_LENGTH - tests.length - numNegTestsToGenerate}
            className="generate-popup-number-input"
          />
        </div>
        <div style={{ width: "35%" }}>
          Number of negative tests ({numNegTestsToGenerate}):
          <input
            disabled={generatingTests}
            type="number"
            value={numNegTestsToGenerate}
            onChange={(e) => {
              if (!e.target.value) setNumNegTestsToGenerate(0);
              else if (
                e.target.value <=
                  MAX_TESTS_LENGTH - tests.length - numPosTestsToGenerate &&
                e.target.value >= 0
              )
                setNumNegTestsToGenerate(Number(e.target.value) || 0);
            }}
            min={0}
            max={MAX_TESTS_LENGTH - tests.length - numPosTestsToGenerate}
            className="generate-popup-number-input"
          />
        </div>
      </div>
      <button
        disabled={
          generatingTests || !(numPosTestsToGenerate || numNegTestsToGenerate)
        }
        className="prompt-generation-button generate-tests-button"
        onClick={generateTests}
      >
        {generatingTests ? "GENERATING..." : "GENERATE"}
      </button>
      {ErrorPopupComponent}
    </>
  );
};

export default EnterTestSetDetails;
