import { useEffect, useState } from "react";
import ConvTestItem from "./ConvTestItem";
import { IconButton } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import ConfirmDeletePopup from "../../popups/admin/ConfirmDeletePopup";

const MAX_DIALOGUE_LENGTH = 10;

const ConvTest = ({
  test,
  testNum,
  editTest,
  setEditing,
  deleteTest,
  proceeding,
  disabled,
}) => {
  const [conv, setConv] = useState(test?.test_conv || []);
  const [expectedResult, setExpectedResult] = useState(test?.expected_result);
  const [beforeEdit, setBeforeEdit] = useState(test ?? {});
  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);

  const edit = () => {
    if (!disabled) {
      setBeforeEdit({
        test_conv: JSON.parse(JSON.stringify(conv)),
        expected_result: expectedResult,
      });
      setEditing(true);
    }
  };

  const saveChanges = () => {
    editTest({
      ...test,
      editing: false,
      test_conv: conv,
      expected_result: expectedResult,
    });
  };

  const discardChanges = () => {
    setConv(JSON.parse(JSON.stringify(beforeEdit.test_conv)));
    setExpectedResult(beforeEdit.expected_result);
    setEditing(false);
  };

  const editConv = (index, data) => {
    let convItem = conv[index];
    convItem[1] = data;
    setConv((prev) => [
      ...prev.slice(0, index),
      convItem,
      ...prev.slice(index + 1),
    ]);
  };

  const addDialogue = () => {
    const speaker = conv.length && conv[conv.length - 1][0] === "B" ? "U" : "B";
    setConv((prev) => [...prev, [speaker, ""]]);
  };

  const removeDialogueFromEnd = () => {
    setConv((prev) =>
      prev
        .map((item, index) => (index !== prev.length - 1 ? item : undefined))
        .filter((item) => item)
    );
  };

  const editExpectedResult = (data) => {
    setExpectedResult(data);
  };

  return (
    <>
      <div className="prompt-generation-test-wrapper">
        <div className="prompt-generation-test-title">
          <h3>Test {testNum}</h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            {!test?.editing ? (
              <button
                className="prompt-generation-button"
                onClick={() => edit()}
                disabled={proceeding || disabled}
              >
                EDIT
              </button>
            ) : (
              <>
                <button
                  className="prompt-generation-button"
                  onClick={saveChanges}
                  disabled={proceeding || disabled}
                >
                  SAVE CHANGES
                </button>
                <button
                  className="prompt-generation-button"
                  style={{ backgroundColor: "#fa7d5f" }}
                  onClick={discardChanges}
                  disabled={proceeding || disabled}
                >
                  DISCARD CHANGES
                </button>
              </>
            )}
            <IconButton
              title={proceeding ? "" : "Delete test"}
              className="prompt-generation-add-another"
              style={{
                position: "absolute",
                background: "#fa7d5f",
                borderRadius: "50%",
                height: "30px",
                width: "30px",
                color: "#fff",
                left: "100%",
                bottom: "90%",
              }}
              onClick={() => setConfirmDeletePopup(true)}
              disabled={proceeding}
            >
              <Delete style={{ height: "20px" }} />
            </IconButton>
          </div>
        </div>
        <div className="prompt-generation-test">
          <div className="prompt-generation-test-conversation">
            {conv?.map((cItem, ind) => (
              <ConvTestItem
                key={`t-${testNum}-${ind}`}
                speaker={cItem[0]}
                content={cItem[1]}
                editConv={(c) => editConv(ind, c)}
                active={test?.editing && !proceeding}
              />
            ))}
            <div style={{ display: "flex" }}>
              {test?.editing ? (
                <>
                  <IconButton
                    title="Add dialogue"
                    className="prompt-generation-add-another"
                    onClick={addDialogue}
                    disabled={conv.length >= MAX_DIALOGUE_LENGTH}
                  >
                    <Add />
                  </IconButton>
                  <IconButton
                    title="Delete last dialogue"
                    className="prompt-generation-add-another"
                    onClick={removeDialogueFromEnd}
                    disabled={!conv.length}
                  >
                    <Delete />
                  </IconButton>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="prompt-generation-test-expected-result">
            Expected result:{" "}
            <select
              disabled={!test?.editing || proceeding}
              value={expectedResult}
              onChange={(e) => editExpectedResult(e.target.value)}
            >
              <option value="True">True</option>
              <option value="False">False</option>
            </select>
          </div>
        </div>
      </div>
      <ConfirmDeletePopup
        open={confirmDeletePopup}
        confirmDelete={deleteTest}
        close={() => setConfirmDeletePopup(false)}
      >
        Are you sure you want to delete this test?
      </ConfirmDeletePopup>
    </>
  );
};
export default ConvTest;
