//packages
import React from "react";
//style
import '../../style/course.css'
//consts
import { vh, vw } from "../../tools/screen";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const CourseSkeleton = () => {



    return (
        <div style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Skeleton circle={true} style={{
                    width: vh(7), height: vh(7)
                }} />
                <Skeleton style={{
                    marginLeft: vw(2), marginTop: vh(1.5),
                    width: vw(25), height: vh(4)
                }} />
            </div>
            <div style={{ marginLeft: vw(7), marginTop: vh(4), display: 'flex', flexDirection: 'row' }}>
                <Skeleton circle={true} style={{
                    width: vh(4), height: vh(4)
                }} />
                <Skeleton style={{
                    marginLeft: vw(2),
                    width: vw(17), height: vh(4)
                }} />
            </div>
            <div style={{ marginLeft: vw(7), marginTop: vh(4), display: 'flex', flexDirection: 'row' }}>
                <Skeleton circle={true} style={{
                    width: vh(4), height: vh(4)
                }} />
                <Skeleton style={{
                    marginLeft: vw(2),
                    width: vw(10), height: vh(4)
                }} />
            </div>
            <div style={{ marginLeft: vw(7), marginTop: vh(4), display: 'flex', flexDirection: 'row' }}>
                <Skeleton circle={true} style={{
                    width: vh(4), height: vh(4)
                }} />
                <Skeleton style={{
                    marginLeft: vw(2),
                    width: vw(15), height: vh(4)
                }} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: vh(5) }}>
                <Skeleton circle={true} style={{
                    width: vh(7), height: vh(7)
                }} />
                <Skeleton style={{
                    marginLeft: vw(2), marginTop: vh(1.5),
                    width: vw(25), height: vh(4)
                }} />
            </div>
            <div style={{ marginLeft: vw(7), marginTop: vh(4), display: 'flex', flexDirection: 'row' }}>
                <Skeleton circle={true} style={{
                    width: vh(4), height: vh(4)
                }} />
                <Skeleton style={{
                    marginLeft: vw(2),
                    width: vw(17), height: vh(4)
                }} />
            </div>
            <div style={{ marginLeft: vw(7), marginTop: vh(4), display: 'flex', flexDirection: 'row' }}>
                <Skeleton circle={true} style={{
                    width: vh(4), height: vh(4)
                }} />
                <Skeleton style={{
                    marginLeft: vw(2),
                    width: vw(10), height: vh(4)
                }} />
            </div>
        </div >
    );
};

export default CourseSkeleton;
