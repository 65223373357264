import { useLayoutEffect, useRef } from "react";

const MIN_TEXTAREA_HEIGHT = 32;

const ConvTestItem = ({ speaker, content, editConv, active }) => {
  const textareaRef = useRef();

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    textareaRef.current.style.height = "inherit";
    // Set height
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      MIN_TEXTAREA_HEIGHT
    )}px`;
  }, [content]);

  return (
    <div
      className={`prompt-generation-test-conversation-item prompt-generation-test-conversation-item-${speaker.toLowerCase()}`}
    >
      <label>{speaker}: </label>
      <textarea
        ref={textareaRef}
        style={{
            minHeight: MIN_TEXTAREA_HEIGHT,
            resize: "none"
        }}
        disabled={!active}
        value={content}
        onChange={(e) => editConv(e.target.value)}
      ></textarea>
    </div>
  );
};

export default ConvTestItem;
